<template>
  <v-container>
    <div class="d-flex justify-end">
      <v-btn color="primary" :to="{ name: 'QuestionPack.Create' }"
        >New Question Pack</v-btn
      >
    </div>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>Question Pack</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-form @submit.prevent="options.search = searchForm">
          <v-text-field
            v-model="searchForm"
            @click:append="clearSearch"
            :append-icon="searchForm ? 'mdi-close' : ''"
            prepend-icon="mdi-magnify"
            :label="$vuetify.lang.t('$vuetify.search')"
            single-line
            hide-details
          >
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-dialog
          ref="dialog"
          v-model="dateFilterModal"
          :return-value.sync="options.dateFilter"
          width="290px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Search by Date"
              prepend-icon="mdi-calendar"
              :append-icon="options.dateFilter[0] !== '' ? 'mdi-close' : ''"
              @click:append="options.dateFilter = ['', '']"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="options.dateFilter" range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateFilterModal = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(options.dateFilter)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <data-table
      :headers="headers"
      :items="questionPacks"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          first-icon="mdi-chevron-double-left"
          last-icon="mdi-chevron-double-right"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          :items-per-page-options="[25, 50, 100, -1]"
          :options="options"
          :pagination="pagination"
          :show-first-last-page="true"
          :show-current-page="true"
        />
      </template>
      <template v-slot:[`item.createdAt`]="{ item: { createdAt } }">
        <span>{{ createdAt | parseStringDate }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item: { id } }">
        <div style="width: 100px">
          <v-btn
            color="warning"
            :to="{ name: 'QuestionPack.Edit', params: { id } }"
            class="mt-1 mb-2"
            medium
            block
            >Edit</v-btn
          >
          <v-btn color="error" class="mb-1" @click="deletePrompt(id)" block
            >Delete</v-btn
          >
        </div>
      </template>
    </data-table>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Delete selected data? </v-card-title>

        <v-card-text>
          This action is irreversible. Deleting this data means other data that
          containing this data will also be deleted.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1"
            @click="deleteData()"
            :loading="loadingDelete"
            text
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import format from "date-fns/format";
import { mapActions } from "vuex";
import DataTable from "@/components/DataTable.js";
import QuestionPack from "@/services/question-pack";

export default {
  name: "QuestionPack.Index",
  components: {
    DataTable
  },
  data() {
    return {
      totalData: 0,
      questionPacks: [],
      loading: true,
      loadingDelete: false,
      options: {
        dateFilter: ["", ""],
        search: undefined
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Subject", value: "subjectName" },
        { text: "Created At", value: "createdAt" },
        { text: "Action", value: "action", sortable: false }
      ],
      dialog: false,
      selectedId: "",
      dateFilterModal: false,
      searchForm: null
    };
  },
  computed: {
    dateRangeText() {
      return this.options.dateFilter.join(" ~ ");
    }
  },
  watch: {
    options: {
      handler() {
        this.getQuestionPacks();
      },
      deep: true
    }
  },
  filters: {
    parseStringDate(date) {
      return format(new Date(date), "yyyy-MM-dd HH:mm");
    }
  },
  created() {
    this.setNavbarTitle("Manage Question Pack");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getQuestionPacks() {
      this.loading = true;
      this.questionPacks = [];
      const questionPacks = await QuestionPack.index(this.options);
      this.questionPacks = questionPacks.data.data.question_packs;
      this.totalData = questionPacks.data.data.total_data;
      this.loading = false;
    },
    async deletePrompt(id) {
      this.selectedId = id;
      this.dialog = true;
    },
    async deleteData() {
      try {
        this.loadingDelete = true;
        await QuestionPack.delete(this.selectedId);
        this.$router.go(this.$router.currentRoute);
      } catch (error) {
        this.loadingDelete = false;
      }
    },
    clearSearch() {
      this.options.search = undefined;
      this.searchForm = null;
    }
  }
};
</script>
